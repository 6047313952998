import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "./ListItem";
import List from "@material-ui/core/List";
import styles from "../../assets/jss/components/headerStyle";
import { useTranslation } from "react-i18next";
import PollIcon from "@material-ui/icons/Poll";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import SettingsIcon from "@material-ui/icons/Settings";
import EventIcon from "@material-ui/icons/Event";
import PersonIcon from "@material-ui/icons/Person";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import CompareIcon from "@material-ui/icons/Compare";
import { UserContext } from "../../contexts/userContext";
import { ClanContext } from "../../contexts/clanContext";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemDefault from "@material-ui/core/ListItem";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useRouteMatch } from "react-router-dom";

export default function Member({ baseUrl }) {
  const { user, logout } = useContext(UserContext);
  const { clan } = useContext(ClanContext);
  const route = useRouteMatch("/:clanSlug/admin");
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(!route);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <List className={classes.list}>
      <ListItemDefault button onClick={handleClick}>
        <ListItemText primary={t("header.sidebar.memberLinks")} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemDefault>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {user && user.id && user.Clan_id === clan.id && (
            <ListItem
              to={`${baseUrl}/members/compare`}
              text={t("header.sidebar.compare")}
              Icon={CompareIcon}
            />
          )}
          <ListItem
            to={`${baseUrl}/hiscores`}
            text={t("header.sidebar.hiscores")}
            Icon={PollIcon}
          />
          <ListItem
            to={`${baseUrl}/requirements`}
            text={t("header.sidebar.requirements")}
            Icon={PlaylistAddCheckIcon}
          />
          <ListItem
            to={`${baseUrl}/items`}
            text={t("header.sidebar.items")}
            Icon={LocalOfferIcon}
          />
          {Object.keys(user).length === 0 && (
            <ListItem
              to={`${baseUrl}/login`}
              text={t("header.sidebar.login")}
              Icon={PersonIcon}
            />
          )}
          {Object.keys(user).length === 0 && (
            <ListItem
              to={`${baseUrl}/register`}
              text={t("header.sidebar.register")}
              Icon={PersonAddIcon}
            />
          )}
          {Object.keys(user).length === 0 && (
            <ListItem
              to={`${baseUrl}/apply`}
              text={t("header.sidebar.apply")}
              Icon={GroupAddIcon}
            />
          )}
          {user && user.id && user.Clan_id === clan.id && (
            <ListItem
              to={`${baseUrl}/events`}
              text={t("header.sidebar.events")}
              Icon={EventIcon}
            />
          )}
          {user && user.id && user.Clan_id === clan.id && (
            <ListItem
              to={`${baseUrl}/members/settings`}
              text={t("header.sidebar.settings")}
              Icon={SettingsIcon}
            />
          )}
          {user && user.id && user.Clan_id === clan.id && (
            <ListItem
              onClick={logout}
              text={t("header.sidebar.logout")}
              Icon={ExitToAppIcon}
            />
          )}
        </List>
      </Collapse>
    </List>
  );
}
